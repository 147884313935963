import Vue from "vue";
import Router from "vue-router";

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

Vue.use(Router);

const router = new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            component: () => import("@/page/Home"),
            children: [
                {
                    path: "/",
                    name: "index",
                    component: () => import("@/page/Index"),
                    meta: {
                        title: "首页 - 代码网",
                    },
                },
                {
                    path: "/blog",
                    name: "blog",
                    component: () => import("@/page/Blog"),
                    meta: {
                        title: "博客 - 代码网",
                    },
                },
                {
                    path: "/blog/:id",
                    name: "blogDetails",
                    component: () => import("@/page/BlogDetails"),
                    meta: {
                        title: " 详情页 - 代码网",
                    },
                },
                {
                    path: "/group",
                    name: "group",
                    component: () => import("@/page/Group"),
                    meta: {
                        title: "专题 - 代码网",
                    },
                },
                {
                    path: "/group/:id",
                    name: "groupDetails",
                    component: () => import("@/page/GroupDetails"),
                    meta: {
                        title: "专题详情页 - 代码网",
                    },
                },
                {
                    path: "/add",
                    name: "add",
                    component: () => import("@/page/NewBlog"),
                    meta: {
                        title: "新增博客 - 代码网",
                    },
                },
                {
                    path: "/bind",
                    name: "bind",
                    component: () => import("@/page/Bind"),
                    meta: {
                        title: "新增博客 - 绑定",
                    },
                },
                {
                    path: "/help",
                    name: "help",
                    component: () => import("@/page/Help"),
                    meta: {
                        title: "关于我们 - 代码网",
                    },
                },
                {
                    path: "/pay",
                    name: "pay",
                    component: () => import("@/page/Pay"),
                    meta: {
                        title: "登支付 - 代码网",
                    },
                },
                {
                    path: "/personal",
                    name: "personal",
                    component: () => import("@/page/Personal"),
                    meta: {
                        title: "个人资料 - 代码网",
                    },
                },
                {
                    path: "/email",
                    name: "email",
                    component: () => import("@/page/Email"),
                    meta: {
                        title: "邮箱 - 代码网",
                    },
                },
                {
                    path: "/sso",
                    name: "sso",
                    component: () => import("@/page/Sso"),
                    meta: {
                        title: "sso登录 - 代码网",
                    },
                },
                {
                    path: "/admin",
                    name: "admin",
                    component: () => import("@/page/admin/index.vue"),
                    meta: {
                        title: "后台管理 - 代码网",
                    },
                },
                {
                    path: "/search",
                    name: "search",
                    component: () => import("@/page/Search.vue"),
                    meta: {
                        title: "搜索管理 - 代码网",
                    },
                },
                {
                    path: "/admin/exception",
                    name: "exception",
                    component: () => import("@/page/admin/exception.vue"),
                    meta: {
                        title: "异常管理 - 代码网",
                    },
                },
                {
                    path: "/admin/indexManager",
                    name: "indexManager",
                    component: () => import("@/page/admin/indexManager.vue"),
                    meta: {
                        title: "首页管理 - 代码网",
                    },
                },
                {
                    path: "/admin/textManager",
                    name: "textManager",
                    component: () => import("@/page/admin/textManager.vue"),
                    meta: {
                        title: "组件管理 - 代码网",
                    },
                },
                {
                    path: "/admin/userManager",
                    name: "userManager",
                    component: () => import("@/page/admin/userManager.vue"),
                    meta: {
                        title: "用户管理 - 代码网",
                    },
                },
                {
                    path: "/admin/viewNum",
                    name: "viewNum",
                    component: () => import("@/page/admin/viewNum.vue"),
                    meta: {
                        title: "流量管理 - 代码网",
                    },
                },
                {
                    path: "/:id",
                    name: "user",
                    component: () => import("@/page/User"),
                    meta: {
                        title: " - 代码网",
                    },
                },
            ],
        },
    ],
});

router.beforeEach((to, from, next) => {
    // 这里通过 to.meta.title 来设置页面标题
    if (to.meta.title) {
        document.title = to.meta.title;
        if (location.href.includes("haowen")) {
            document.title = document.title.replace("代码网", "好文网");
        }
    }
    next();
});

export default router;
